import { Component, ElementRef, ViewChild } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Subscription } from 'rxjs';
export class TestObject {
  range:string;
  }
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private subscription: Subscription;
  topicname: "range";
  msg: any;
  display = false;
  qrInfo: any;
  isConnected: boolean = false;
  title = 'Distance';
  testObject: TestObject;
  range:string;
  @ViewChild("msglog", { static: true }) msglog: ElementRef;
  constructor(private _mqttService: MqttService){
    _mqttService.connect({username:'PetalHosUser',password:'PetalHosEmq@20#19!'});
 
  }
  ngOnInit(): void {
    this.subscribeNewTopic();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeNewTopic(): void {
    console.log("inside subscribe new topic");
    this.subscription = this._mqttService
      .observe("range")
      .subscribe((message: IMqttMessage) => {       
         this.qrInfo=  message.payload.toString();
this.testObject = new TestObject();
this.testObject=this.qrInfo;


      });
    }
}
